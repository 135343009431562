import { GetLandingResponse, WidgetType } from "../types/index.d";

export const DEFAULT_SECTION_TITLE = "Eventos Anteriores";
export const DEFAULT_LOGO_URL = "";
export const DEFAULT_COLOR = "#EDE7E2";

export const DEFAULT_PRODUCTS_TITLE = "Título de categoría";

export enum LogoAlignment {
	LEFT = "LEFT",
	CENTER = "CENTER",
}

export const DEFAULT_CONFIG: GetLandingResponse = {
	id: "",
	storeId: undefined,
	name: "DEFAULT",
	active: true,
	shoppingCart: false,
	phoneNumber: {country: '', number: '', operator: '', type: ''},
	storeNumber: {country: '', number: '', operator: '', type: ''},
	productCatalog: false,
	storeCurrency: "",
	storeName: "",
	firaLandingConfiguration: {
		bgColor: DEFAULT_COLOR,
		webLogoUrl: DEFAULT_LOGO_URL,
		mobileLogoUrl: DEFAULT_LOGO_URL,
		logoAlignment: LogoAlignment.CENTER,
		storeUrl: "",
		headerHeight: 72,
		mobileCentered: true,
		headerColor: DEFAULT_COLOR,
		footerColor: DEFAULT_COLOR,
		widgetContent: {
			bgColor: "#fff",
			displayColor: DEFAULT_COLOR,
			detailsColor: DEFAULT_COLOR,
			collectionId: "",
			previousEvents: "",
			fontColor: "#000",
			sectionTitle: "Eventos Anteriores",
			widgetId: "",
			widgetType: WidgetType.vod,
		},
	},
	featuredProducts: [],
	products: [],
};
