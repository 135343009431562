import React, {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from "react";
import { useLocation } from "react-router-dom";
import {
	DEFAULT_COLOR,
	DEFAULT_CONFIG,
	DEFAULT_LOGO_URL,
	DEFAULT_PRODUCTS_TITLE,
	DEFAULT_SECTION_TITLE,
	LogoAlignment,
} from "../utils/constants";
import { getContrastColor } from "../utils/getContrastColor";
import { GetLandingResponse, WidgetType } from "../types/index.d";

export enum LandingMode {
	PREVIEW,
	NORMAL,
}

const HEADER_TIMER = 1000;

interface LandingContextType {
	mode: LandingMode;
	configuration?: GetLandingResponse;
	contrastColor: string;
	currentVideo?: string;
	setCurrentVideo: React.Dispatch<React.SetStateAction<string>>;
	showHeaderBriefly: () => void;
	showHeader: boolean;
}
const LandingContext = createContext<LandingContextType | undefined>(undefined);

interface ChatProviderProps {
	children: ReactNode;
	slug: string;
	mode: LandingMode;
	configuration?: GetLandingResponse;
	mainColor?: string;
}
export const LandingProvider: React.FC<ChatProviderProps> = ({
	children,
	slug,
	mode,
	configuration,
	mainColor,
}) => {
	const [currentConfiguration, setCurrentConfiguration] =
		useState<GetLandingResponse>(configuration || DEFAULT_CONFIG);

	const [currentVideo, setCurrentVideo] = useState<string>("not_found");

	const [currentContrastColor, setCurrentContrastColor] = useState(
		getContrastColor(mainColor || DEFAULT_COLOR)
	);
	const location = useLocation();

	const [showHeader, setShowHeader] = useState(false);
	const showHeaderBriefly = React.useCallback(() => {
		setShowHeader(true);
		setTimeout(() => setShowHeader(false), HEADER_TIMER);
	}, []);

	const getLogoAlignment = (logoAlignmentParam: string | null) => {
		if (logoAlignmentParam) {
			switch (logoAlignmentParam.toUpperCase()) {
				case "LEFT":
					return LogoAlignment.LEFT;
				case "CENTER":
					return LogoAlignment.CENTER;

				default:
					return LogoAlignment.CENTER;
			}
		} else {
			return LogoAlignment.CENTER;
		}
	};
	//headerColor,footerColor,bgColor,headerHeight,logoAlignment,webLogoUrl,mobileLogoUrl
	// WIDGETS: bgColorW,displayColor,detailsColor,fontColor
	const [queryParamsState, setQueryParamsState] = useState(
		new URLSearchParams()
	);

	useEffect(() => {
		setQueryParamsState(new URLSearchParams(location.search));
	}, [location.search]);

	useEffect(() => {
		const queryParams = queryParamsState;

		if (mode === LandingMode.PREVIEW) {
			//const queryParams = new URLSearchParams(location.search);
			console.log("firakey", queryParams.get("firaKey"));
			const firaKey = queryParams.get("firaKey")
				? `${queryParams.get("firaKey")}`
				: "";

			// COLOR DE HEADER
			const headerColor = queryParams.get("headerColor")
				? `#${queryParams.get("headerColor")}`
				: DEFAULT_COLOR;

			// COLOR DE FOOTER
			const footerColor = queryParams.get("footerColor")
				? `#${queryParams.get("footerColor")}`
				: DEFAULT_COLOR;
			//color de contraste definido en base a color de footer
			setCurrentContrastColor(getContrastColor(footerColor));

			// COLOR DE FONDO
			const bgColor = queryParams.get("bgColor")
				? `#${queryParams.get("bgColor")}`
				: DEFAULT_COLOR;

			const headerHeightParam = queryParams.get("headerHeight");
			const headerHeight = headerHeightParam
				? parseInt(headerHeightParam, 10)
				: 72;

			// ALINECION DE LOGO
			const logoAlignment = getLogoAlignment(queryParams.get("logoAlignment"));

			//widget params
			const bgColorW = queryParams.get("bgColorW")
				? `#${queryParams.get("bgColorW")}`
				: "#FFF";

			const displayColor = queryParams.get("displayColor")
				? `#${queryParams.get("displayColor")}`
				: DEFAULT_COLOR;

			const detailsColor = queryParams.get("detailsColor")
				? `#${queryParams.get("detailsColor")}`
				: DEFAULT_COLOR;

			const fontColor = queryParams.get("fontColor")
				? `#${queryParams.get("fontColor")}`
				: "#000000";
			const webLogoUrl = queryParams.get("webLogoUrl")
				? `${queryParams.get("webLogoUrl")}`
				: DEFAULT_LOGO_URL;

			const mobileLogoUrl = queryParams.get("mobileLogoUrl")
				? `${queryParams.get("mobileLogoUrl")}`
				: DEFAULT_LOGO_URL;
			const sectionTitle = queryParams.get("sectionTitle")
				? `${queryParams.get("sectionTitle")}`
				: queryParams.get("widgetType") === "VOD"
				  ? DEFAULT_SECTION_TITLE
				  : DEFAULT_PRODUCTS_TITLE;

			const fontFooter = queryParams.get("fontFooter")
				? `${queryParams.get("fontFooter")}`
				: "Monserrat";

			const shoppingCart = queryParams.get("shoppingCart") === 'true'

			const productCatalog = queryParams.get("productCatalog") === 'true'

			const collectionId = queryParams.get("collectionId")
				? `${queryParams.get("collectionId")}`
				: "";
			const previousEvents = queryParams.get("previousEvents")
				? `${queryParams.get("previousEvents")}`
				: "";

			const fontSizeFooter = queryParams.get("fontSizeFooter")
				? Number(queryParams.get("fontSizeFooter"))
				: 0;
			const fontSizeContent = queryParams.get("fontSizeContent")
				? Number(queryParams.get("fontSizeContent"))
				: 0;
			const fontContent = queryParams.get("fontContent")
				? `${queryParams.get("fontContent")}`
				: "";
			const fontWeightContent = queryParams.get("fontWeightContent")
				? Number(queryParams.get("fontWeightContent"))
				: 0;
			const fontWeightFooter = queryParams.get("fontWeightFooter")
				? Number(queryParams.get("fontWeightFooter"))
				: 0;
			const widgetType = queryParams.get("widgetType")
				? (`${queryParams.get("widgetType")}` as WidgetType)
				: WidgetType.vod;

			setCurrentConfiguration({
				...currentConfiguration,
				storeId: firaKey,
				shoppingCart,
				productCatalog,
				firaLandingConfiguration: {
					bgColor,
					webLogoUrl,
					mobileLogoUrl,
					logoAlignment,
					storeUrl: "",
					headerHeight,
					mobileCentered: true,
					fontFooter,
					headerColor,
					footerColor,
					fontSizeFooter,
					fontSizeContent,
					fontContent,
					fontWeightContent,
					fontWeightFooter,
					widgetContent: {
						bgColor: bgColorW,
						displayColor,
						detailsColor,
						collectionId,
						previousEvents,
						fontColor,
						sectionTitle,
						widgetId: "",
						widgetType,
					},
				},
			});
			//console.log("set", currentConfiguration);
		}
	}, [queryParamsState, mode]);

	return (
		<LandingContext.Provider
			value={{
				mode,
				configuration: currentConfiguration,
				contrastColor: currentContrastColor,
				currentVideo,
				setCurrentVideo,
				showHeaderBriefly,
				showHeader,
			}}
		>
			{children}
		</LandingContext.Provider>
	);
};

export const useLandingContext = () => {
	const context = useContext(LandingContext);

	if (context === undefined) {
		throw new Error("useLandingContext must be use within an LandingProvider");
	}

	return context;
};
