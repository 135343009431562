import React, { ReactNode, useEffect, useState } from "react";

import stylesheet from "./Header.module.scss";
import ImageWithFallback from "../ImageWithFallback/ImageWithFallback";
import NoPhotoFile from "../../assets/no_photo.png";
import { LogoAlignment } from "../../utils/constants";
import Shopping from "../FiraIcons/Shopping";
import { CartProduct, useCart } from "../../providers/CartContext";
import FiraShoppingCart from "../FiraShoppingCart/FiraShoppingCart";
import { Link, NavLink } from "react-router-dom";
import { useClickOutside } from "../../utils/useClickOutside";
import { useLandingContext } from "../../providers/LandingProvider";

const {
	HeaderWrapper,
	Logo,
	LogoLeft,
	LogoMobile,
	shoppingCart,
	cartCounter,
	shoppingCartDetail,
	shoppingCartDetailMobile,
	activeLink,
	inactiveLink,
	HeaderBlock,
	HeaderFixed,
} = stylesheet;

interface HeaderProps {
	color: string;
	logoURL?: string;
	alignLogo?: string;
	headerHeight: number;
	fontHeader?: string;
	isMobile: boolean;
	slug: ReactNode;
	contrastColor: string;
	showCatalog: boolean;
}

const Header: React.FC<HeaderProps> = ({
	color,
	logoURL,
	alignLogo,
	headerHeight,
	fontHeader,
	isMobile,
	slug,
	contrastColor,
	showCatalog
}) => {
	const { cart } = useCart();
	const { showHeader, mode } = useLandingContext();
	const [isCartVisible, setIsCartVisible] = useState<boolean>(false);
	const [logo, setLogo] = useState("");
	const [lastScrollY, setLastScrollY] = useState(0);
	const [headerClass, setHeaderClass] = useState(HeaderFixed);
	const isIOS =
		/iPad|iPhone|iPod/.test(navigator.userAgent) &&
		!window.navigator.userAgent.includes("CriOS");

	const domNode = useClickOutside(() => setIsCartVisible(false));

	useEffect(() => {
		logoURL && setLogo(logoURL);
	}, [logoURL]);

	const calculateItemTotal = (products: CartProduct[]) => {
		return products.reduce((sum, item) => {
			const totalQuantity = +item.quantity;
			return sum + totalQuantity;
		}, 0);
	};

	const controlHeader = () => {
		const currentScrollY = window.scrollY;
		if (currentScrollY < lastScrollY) {
			setHeaderClass(HeaderFixed);
		} else {
			setHeaderClass(HeaderBlock);
			setIsCartVisible(false);
		}
		setLastScrollY(currentScrollY);
	};

	const toggleBodyScroll = (shouldEnable: boolean) => {
		if (shouldEnable) {
			document.body.style.overflow = "auto";
		} else {
			document.body.style.overflow = "hidden";
		}
	};

	const onCartToggle = () => {
		if (!isMobile) {
			toggleBodyScroll(isCartVisible);
		}
		setIsCartVisible(!isCartVisible);
	};

	const onCloseCart = () => {
		toggleBodyScroll(true);
		setIsCartVisible(false);
	};

	const active = {
		color: "red",
	};

	const disactive = {
		default: {
			color: "#eee",
		},
		Hovered: {
			color: "red",
		},
	};

	React.useEffect(() => {
		if (!isIOS) {
			window.addEventListener("scroll", controlHeader);
			return () => {
				window.removeEventListener("scroll", controlHeader);
			};
		}
	}, [lastScrollY]);

	useEffect(() => {
		if (showHeader && headerClass === HeaderBlock) {
			setHeaderClass(HeaderFixed);
			setTimeout(() => setHeaderClass(HeaderBlock), 1000);
		}
	}, [showHeader]);

	return (
		<div
			className={`${HeaderWrapper} ${headerClass}`}
			style={{
				background: color,
				justifyContent:
					alignLogo === LogoAlignment.LEFT
						? LogoAlignment.LEFT
						: LogoAlignment.CENTER,

				fontFamily: fontHeader,
			}}
		>
			{alignLogo === LogoAlignment.CENTER && (
				<>
					<nav
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<ul
							style={{
								listStyle: "none",
								margin: 0,
								padding: 0,
								display: "flex",
								alignItems: "center",
								gap: !isMobile ? "20px" : "50px",
							}}
						>
							{showCatalog && <li style={{ flex: "2" }}>
								<NavLink
									to={`/${slug}`}
									end
									style={({ isActive }) =>
										isActive
											? {
													textDecorationLine: "underline",
													color: contrastColor,
													fontSize: "16px",
													fontFamily: "Montserrat",
													fontWeight: 700,
													textAlign: "center",
											  }
											: {
													textDecorationLine: "none",
													color: contrastColor,
													fontSize: "16px",
													fontFamily: "Montserrat",
													fontWeight: 600,
													textAlign: "center",
											  }
									}
								>
									Canal
								</NavLink>
							</li>}
							<Link to={`/${slug}`}>
								<ImageWithFallback
									className={Logo}
									src={logo ? logo : NoPhotoFile}
									fallbackSrc={NoPhotoFile}
									alt="Logo"
								/>
							</Link>
							{showCatalog && <li style={{ flex: "2" }}>
								{
									<NavLink
										to={`/${slug}/catalogo`}
										style={({ isActive }) =>
											isActive
												? {
														textDecorationLine: "underline",
														color: contrastColor,
														fontSize: "16px",
														fontFamily: "Montserrat",
														fontWeight: 700,
														textAlign: "center",
												  }
												: {
														textDecorationLine: "none",
														color: contrastColor,
														fontSize: "16px",
														fontFamily: "Montserrat",
														fontWeight: 600,
														textAlign: "center",
												  }
										}
									>
										Catálogo
									</NavLink>
								}
							</li>}
						</ul>
					</nav>
				</>
			)}

			{alignLogo === LogoAlignment.LEFT && (
				<>
					<ImageWithFallback
						className={`${Logo} ${!isMobile ? LogoMobile : LogoLeft}`}
						src={logo ? logo : NoPhotoFile}
						fallbackSrc={NoPhotoFile}
						alt="Logo"
					/>
					<nav
						style={{
							display: "flex",
							flexDirection: "row", // Asegúrate de que el nav sea una fila
							alignItems: "center",
							width: "100%",
							justifyContent: "center",
							position: "absolute", // Centra verticalmente los elementos dentro del nav
						}}
					>
						{showCatalog && <ul
							style={{
								listStyle: "none",
								margin: 0,
								padding: 0,
								display: "flex",
								alignItems: "center",
							}}
						>
							<li style={{ margin: "0 10px" }}>
								<NavLink
									to={`/${slug}`}
									end
									style={({ isActive }) =>
										isActive
											? {
													textDecorationLine: "underline",
													color: contrastColor,
													fontSize: "16px",
													fontFamily: "Montserrat",
													fontWeight: 700,
													textAlign: "center",
											  }
											: {
													textDecorationLine: "none",
													color: contrastColor,
													fontSize: "16px",
													fontFamily: "Montserrat",
													fontWeight: 600,
													textAlign: "center",
											  }
									}
								>
									Canal
								</NavLink>
							</li>
							 <li style={{ margin: "0 10px" }}>
								{
									<NavLink
										to={`/${slug}/catalogo`}
										style={({ isActive }) =>
											isActive
												? {
														textDecorationLine: "underline",
														color: contrastColor,
														fontSize: "16px",
														fontFamily: "Montserrat",
														fontWeight: 700,
														textAlign: "center",
												  }
												: {
														textDecorationLine: "none",
														color: contrastColor,
														fontSize: "16px",
														fontFamily: "Montserrat",
														fontWeight: 600,
														textAlign: "center",
												  }
										}
									>
										Catálogo
									</NavLink>
								}
							</li>
						</ul>}
					</nav>
				</>
			)}
			<div className={shoppingCart} onClick={onCartToggle}>
				<div className={cartCounter}>{calculateItemTotal(cart)}</div>
				<Shopping color={contrastColor} />
			</div>

			{isCartVisible && (
				<div
					ref={domNode}
					className={isMobile ? shoppingCartDetail : shoppingCartDetailMobile}
				>
					<FiraShoppingCart
						color={color}
						isMobile={isMobile}
						onClose={onCloseCart}
					/>
				</div>
			)}
		</div>
	);
};

export default Header;
