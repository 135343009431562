import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { LandingMode, LandingProvider } from "./providers/LandingProvider";
import { Main } from "./components/Main/Main";

import { GetLandingResponse } from "./types";
import { getLandingConfig } from "./services";
import style from "./App.module.scss";
import { CartProvider } from "./providers/CartContext";
import Catalogo from "./components/Catalogo/Catalogo";
import HomeLayout from "./views/HomeLayout";

function App() {
	const [currentMode, setCurrentMode] = useState<LandingMode>();
	const [isLoading, setIsLoading] = useState(true);
	const [configuration, setConfiguration] = useState<GetLandingResponse>();
	const [currentMainColor, setCurrentMainColor] = useState<string>();
	const location = useLocation();
	const [slug, setSlug] = useState("");

	useEffect(() => {
		const getConfiguration = async () => {
			const pathnameParts = location.pathname.split("/");
			const slug = pathnameParts[1];

			setSlug(slug);
			try {
				const response = await getLandingConfig(slug);
				setConfiguration(response.data);
				const { bgColor } = response.data.firaLandingConfiguration;
				setCurrentMainColor(bgColor);

				setIsLoading(false);
			} catch (error) {
				setIsLoading(true);
				console.error(error);
			}
		};

		const queryParams = new URLSearchParams(location.search);
		const mode: LandingMode = parseInt(queryParams.get("mode") || "1");
		setCurrentMode(mode);

		if (mode === LandingMode.NORMAL) {
			getConfiguration();
		} else {
			setIsLoading(false);
		}
	}, [location]);

	if (currentMode === LandingMode.NORMAL && isLoading) {
		return (
			<div className={style.container}>
				<div className={style.spinner}></div>
			</div>
		);
	}

	return (
		<LandingProvider
			slug={slug}
			mode={currentMode || LandingMode.PREVIEW}
			mainColor={currentMainColor}
			configuration={configuration}
		>
			<CartProvider storeId={configuration?.storeId || ""}>
				<Routes>
					<Route path={`/`} element={<HomeLayout />}>
						<Route path={`/:slug`} element={<Main />} />
						{configuration && configuration.productCatalog && (
							<Route path={`/:slug/catalogo`} element={<Catalogo />} />
						)}
					</Route>
				</Routes>
			</CartProvider>
		</LandingProvider>
	);
}

export default App;
